<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <!-- <div class="col-12 md:col-6">
    <app-basic-input
      label="Key"
      [placeholder]="'Enter Key'"
      [control]="formGroup?.controls?.key"
      [viewMode]="fieldViewMode"
      [patternError] = "'Must start with a letter, and can only contain letters, digits, underscores, and hyphens.'"
    ></app-basic-input>
  </div> -->
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Field Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <!-- <div class="col-12 md:col-6">
  <app-basic-input
    label="Field Name"
    [placeholder]="'Field Name'"
    [control]="formGroup?.controls?.name"
    [viewMode]="fieldViewMode"
  ></app-basic-input>
</div> -->
    <div class="col-12 md:col-6">
      <app-dynamic-field-type-selector
        [control]="formGroup?.controls?.type"
        [viewMode]="fieldViewMode"
        [appendTo]="'body'"
        label="Type"
        [placeholder]="'Search Types'"
      >
      </app-dynamic-field-type-selector>
    </div>
    <app-dynamic-field-type-options
      [control]="formGroup?.controls?.options"
      [viewMode]="fieldViewMode"
      label="Options"
      [placeholder]="'Options'"
      [fieldType]="formGroup?.controls?.type?.value"
      class="formgrid grid p-fluid w-full m-auto"
    ></app-dynamic-field-type-options>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
